import React from 'react'
import { Link } from 'gatsby'
import { DropDown, DropItem, DropDownBody } from "./tags-component"

class AboutDropdown extends React.Component {
	render(){
		return(
			<DropDown className="dropdown-menu">	
				<DropDownBody className="dropdowm-body">					
					<DropItem><Link to='/what-to-expect'>What to Expect</Link></DropItem>					
					<DropItem><Link to='/reviews'>Reviews</Link></DropItem>
					<DropItem><Link to='/about-us'>About Us</Link></DropItem>
					<DropItem><Link to='/contact'>Contact Us</Link></DropItem>
				</DropDownBody>
			</DropDown>
		)
	}
}

export default AboutDropdown