import React from "react"
import styled from "styled-components"
import { MapIcon, PhoneLineIcon,  EmailIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

const AddressGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -15px;
`
const AddressItemGrid = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  ${BreakpointUp.md`
    flex: 0 0 50%;
    max-width: 50%;
  `}
	${BreakpointUp.xl`
    flex: 0 0 100%;
    max-width: 100%;
	`}
`
const ListInfoTitle = styled.div`
  color:#000;
  font-weight:700;
  margin-bottom:0;
  font-size:18px;
  line-height:28px;
`
const ListInfo = styled.div`
  margin:0 0 20px;
  ${BreakpointDown.md`
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  `}
`

const ListItemInfo = styled.div`  
  display:flex;
  flex-wrap:nowrap;  
  margin-bottom:0;
  padding:10px 0;
  ${BreakpointDown.md`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointDown.sm`
    flex: 0 0 100%;
    max-width: 100%;
  `}
  > .icon{
    width: 24px;
    height: 26px;
    display: flex;
    align-items: center;    
    & svg{
      fill:#444;
    }
    + .text{
      margin-left:20px;
    }
  }
  .text{
    color:#666;
    font-weight:600;
    font-size:14px; 
    line-height: 28px; 
    @media (min-width: 1600px){
      font-size:16px; 
      line-height: 30px; 
    }
  }
  & a{
    display:block;      
    color:#666;
    text-decoration:underline;
    &:hover{
      color:#000;
      text-decoration:none;
    }
  }
`

const ListHour = styled.div`
  margin:10px 0 0;
  font-size:14px; 
  line-height: 28px; 
  column-count: inherit;
  @media (min-width: 567px){
    column-count: 2;
  }
  @media (min-width: 1200px){
    column-count: inherit;

  }
  @media (min-width: 1600px){
    font-size:16px; 
    line-height: 30px; 
  }

`

const ListItemHour = styled.div`  
  margin:0 ;
`


const Address = () => {
  
  return (
    <AddressGrid>
      <AddressItemGrid>
        <ListInfoTitle>Coast To Coast Providing The Best Metal Buildings In The United States!</ListInfoTitle>
        <ListInfo className="list-info">
            <ListItemInfo>
              <span className="icon"><MapIcon /></span>
              <span className="text">22525 I-40 Knoxville, AR 72845</span>
            </ListItemInfo>
            <ListItemInfo>
              <span className="icon"><PhoneLineIcon /></span>
              <span className="text"><a href="tel:8666817846">(866) 681-7846</a></span>
            </ListItemInfo> 
            <ListItemInfo>
                <span className="icon"><EmailIcon /></span>
                <span className="text"><a href="mailto:info@coast-to-coastcarports.com">info@coast-to-coastcarports.com</a></span>
            </ListItemInfo>
        </ListInfo>
      </AddressItemGrid>

      <AddressItemGrid>
        <ListInfoTitle>Operating Hours</ListInfoTitle>
        <ListHour className="list-hour">
          <ListItemHour>Monday 8 AM - 5 PM</ListItemHour>
          <ListItemHour>Tuesday 8 AM - 5 PM</ListItemHour>
          <ListItemHour>Wednesday 8 AM - 5 PM</ListItemHour>
          <ListItemHour>Thursday 8 AM - 5 PM</ListItemHour>
          <ListItemHour>Friday 8 AM - 5 PM</ListItemHour>
          <ListItemHour>Saturday Closed</ListItemHour>
          <ListItemHour>Sunday Closed</ListItemHour>
        </ListHour>
      </AddressItemGrid>
    </AddressGrid>
  )
}

export default Address
