import React from "react"
import styled, { keyframes } from "styled-components";
import BreakpointUp from "../Media/BreakpointUp"

const MarkqueeRightToLeft = keyframes`
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
`;

const MarkqueeWrap = styled.div`
    display: block;
    height: 30px;
    width: 100%;
    background:#b30000;
    padding: 5px;
    color: #fff;
    position: fixed;
    z-index: 10;
    top: 54px;
    font-weight:700;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 1200px) {
        font-size: 16px;
        line-height: 20px;
    }
    ${BreakpointUp.xl`
        top:120px;
    `}
    & a{
        font-weight:600;
        color:#fff;
        font-size: 14px;
        line-height: 1;
        height: 20px;
        &:hover, &:focus{
            color:#fff;
        }
    }
`
const Markquee = styled.div`   
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    text-align:center;
`
const SiteMarkquee = styled.div`
    display: inline-block; 
    padding-left: 100%;
    animation-duration: 25s;
    animation-name: ${MarkqueeRightToLeft};
    animation-iteration-count:infinite;
    animation-timing-function: linear;
    &:hover { 
        animation-play-state: paused; 
    }
    strong{
        color:#ffff00;
    }
`


const Markquees = () => {
  return (
    <MarkqueeWrap className="markquee">
        <div className="container">
            <Markquee>
                <SiteMarkquee>The new pricing is being updated. Please call at <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> for accurate pricing.</SiteMarkquee>
            </Markquee>
        </div>
    </MarkqueeWrap>
  )
}

export default Markquees