import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const DefaultBtn = styled(BaseButton)`
	background: #0B619B;
	border-color:#0B619B;
	color:#fff;		
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		background-color: transparent;
		border-color:#D3D3D3;
		color:#000;
		&:before {
			background: #fff;
		}
		& .icon{
			> svg{
				fill:#000;
			}
		}
	
	}
`

const DefaultButton = (props) => {
	const { icon, text } = props;
	return(
		<DefaultBtn className='btn'>
			<span className='icon'>{icon}</span><span className='text'>{text}</span>
		</DefaultBtn>
	)
}

export default DefaultButton