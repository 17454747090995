import React from "react"
import { Link } from "gatsby"
import { ViewLink } from "../../Link"
import {
  DropDown,
  DropItem,
  DropDownBody,
  DropDownFooter,
} from "./tags-component"

class CarportsDropdown extends React.Component {
  render() {
    return (
      <DropDown className="dropdown-menu">
        <DropDownBody className="dropdowm-body">
          {/* <DropItem>
            <Link to="/a-frame-horizontal-carports">A-Frame Horizontal</Link>
          </DropItem>
          <DropItem>
            <Link to="/a-frame-vertical-carports">A-Frame Vertical</Link>
          </DropItem>
          <DropItem>
            <Link to="/regular-style-carports">Regular Style Carports</Link>
          </DropItem> */}
          <DropItem>
            <Link to="/one-car-carports">One Car Carports</Link>
          </DropItem>
          <DropItem>
            <Link to="/two-car-carports">Two Car Carports</Link>
          </DropItem>
          <DropItem>
            <Link to="/three-car-carports">Three Car Carports</Link>
          </DropItem>
          <DropItem>
            <Link to="/deluxe-carports">Deluxe Enclosed Carports</Link>
          </DropItem>
          <DropItem>
            <Link to="/rv-carports">RV Carports</Link>
          </DropItem>
          <DropItem>
            <Link to="/boat-garages-sheds">Boat Garages and Sheds</Link>
          </DropItem>
          <DropItem>
            <Link to="/utility-carports">Utility Carports</Link>
          </DropItem>
          <DropItem>
            <Link to="/camper-carports-shelters">Camper Carports</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-carport-prices">Metal Carport Prices</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-carport-kits">Metal Carport Kits</Link>
          </DropItem>
        </DropDownBody>
        <DropDownFooter>
          <ViewLink to="/metal-carports">View All</ViewLink>
        </DropDownFooter>
      </DropDown>
    )
  }
}

export default CarportsDropdown
