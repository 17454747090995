import styled from "styled-components"
import { Link } from "gatsby"
import BreakpointUp from "../Media/BreakpointUp"

export const ViewLink = styled(Link)`
	display: block;	
	font-size:16px;
	font-weight:500;
	color:#FC0002 !important;	
	padding:10px 30px;
	${BreakpointUp.xl`			
	padding:10px 20px;
	`}
	& .text{
		line-height: 26px;
		display: inline-block;
		vertical-align: middle;	
		+ .icon{
			margin-left:10px;
		}
		
	}
	& .icon{
		width: 18px;
		height: 18px;		
		display: inline-block;
		vertical-align: middle;	
		text-align:center;		
		& svg{
			fill:#FC0002 !important;
		}
	}
	&:hover{
		color:#000 !important;
	}
`
