import React from 'react';

const RightLineArrowIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
		<path 
			d="M9.864 0l2.536 7.778-2.536 8.222 10.136-8zM0 6.666h12.67v2.667h-12.67z" 
			fill={props.fill}
		/>
	</svg>
)

export default RightLineArrowIcon

RightLineArrowIcon.defaultProps = {
	fill: '#ffffff'
}