import React from 'react'
import { Link } from 'gatsby'
import { DropDown, DropItem, DropDownBody } from "./tags-component"

class BuyerDropdown extends React.Component {
	render(){
		return(
			<DropDown className="dropdown-menu">	
				<DropDownBody className="dropdowm-body">								
					<DropItem><Link to='/3d-metal-building-color-planner'>3D Color Planner</Link></DropItem>					
					<DropItem><Link to='/color-options'>Color Samples</Link></DropItem>						
					<DropItem><Link to='/insulation'>Insulation</Link></DropItem>		
					<DropItem><Link to='/service-area'>Service Area</Link></DropItem>	
					<DropItem><Link to='/frequently-asked-questions'>FAQs</Link></DropItem>
					<DropItem><Link to='/articles'>Blog</Link></DropItem>
					<DropItem><Link to='/infographics'>Infographics</Link></DropItem>	
				</DropDownBody>
			</DropDown>
		)
	}
}

export default BuyerDropdown