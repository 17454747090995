import React from "react"
import styled from "styled-components"
import { FacebookIcon, PinterestIcon, TwitterIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"

const SocialIcon = styled.ul`
  display: flex;
  margin:0 -6px;
  padding: 0;  
  justify-content: center;
  ${BreakpointUp.sm`
    justify-content: space-between;
  `}
`
const SocialItem = styled.li`
  list-style: none;    
  padding:0 6px;
  margin-bottom:0;
  a{
    width: 38px;
    height: 38px;
    border-radius: 12px;
    border: 1px solid #DBDBDB;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      fill:#666;
    }
    &:hover{
      border-color:#000;     
      svg{
        fill:#000;
      }
    }
  }
`
const SocialNetwork = () => {
	return(	
    <SocialIcon>
      <SocialItem><a className="item-link" href="https://www.facebook.com/CoastToCoastCarportsIncUS/" target="_blank" rel="noreferrer" aria-label="Facebook"><FacebookIcon /></a></SocialItem>
      <SocialItem><a className="item-link" href="https://twitter.com/C2CCarports" target="_blank" rel="noreferrer" aria-label="Twitter"><TwitterIcon /></a></SocialItem>
      <SocialItem><a className="item-link" href="https://www.pinterest.com/metalcarports/" target="_blank" rel="noreferrer" aria-label="Pinterest"><PinterestIcon /></a></SocialItem>
    </SocialIcon> 			
	)
}


export default SocialNetwork