import React from "react"
import { Link } from "gatsby"
import { ViewLink } from "../../Link"
import {
  DropDown,
  DropItem,
  DropDownBody,
  DropDownFooter,
} from "./tags-component"

class AgricultureBuildingsDropdown extends React.Component {
  render() {
    return (
      <>
      <DropDown className="dropdown-menu dropdown-megamenu">
        <DropDownBody className="dropdowm-body">
          <DropItem>
            <Link to="/farm-buildings">Farm Buildings</Link>
          </DropItem>
          <DropItem>
            <Link to="/regular-style-barns">Regular Style Barns</Link>
          </DropItem>
          <DropItem>
            <Link to="/horizontal-roof-barns">Horizontal Roof Barns</Link>
          </DropItem>
          <DropItem>
            <Link to="/vertical-roof-barns">Vertical Roof Barns</Link>
          </DropItem>
        </DropDownBody>
        <DropDownBody className="dropdowm-body">
          <DropItem>
            <Link to="/horse-barns">Horse Barns</Link>
          </DropItem>
          <DropItem>
            <Link to="/gambrel-barns">Gambrel Barns</Link>
          </DropItem>
          <DropItem>
            <Link to="/raised-center-barns">Raised Center Barns</Link>
          </DropItem>
          <DropItem>
            <Link to="/straight-roof-barns">Straight Roof Barns</Link>
          </DropItem>
        </DropDownBody>
        <DropDownBody className="dropdowm-body">
          <DropItem>
            <Link to="/loafing-sheds">Loafing Sheds</Link>
          </DropItem>
          <DropItem>
            <Link to="/horse-stalls-shelters">Horse Stalls and Shelters</Link>
          </DropItem>
          <DropItem>
            <Link to="/riding-arenas">Riding Arenas</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-barn-prices">Metal Barn Prices</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-barn-kits">Metal Barn Kits</Link>
          </DropItem>
        </DropDownBody>
        <DropDownFooter>
          <ViewLink to="/farm-buildings">View All</ViewLink>
        </DropDownFooter>
      </DropDown>
      </>
    )
  }
}

export default AgricultureBuildingsDropdown
