import React from 'react'
import styled from 'styled-components'

export const  FormBtn = styled.button`		
	background-color: #B30000;
	border: 1px solid #B30000;
	color:#fff;	
	display: inline-flex;
	align-items: center;
	justify-content: center;	
	text-align:center;
	padding:16px 40px;
	font-size:18px;
	font-weight:700;
	position:relative;		
	transition: all .4s ease-in-out;	
	border-radius: 100px;
	overflow: hidden;
	&:before {
		border-radius: 100px;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:transparent;
		opacity: 0;		
		transform: scale3d(0.7, 1, 1);		
		transition: transform 0.4s, opacity 0.4s;		
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}
	&:hover{
		background-color: transparent;
		border-color:#0B619B;
		color:#fff;
		&:before {
			opacity: 1;
			transform: translate3d(0, 0, 0);			
			background: #0B619B;
		}
		
	}
	
	> .text{
		line-height: 26px;
		display: inline-flex;
		align-items: center;
		position: relative;
		+ .icon{
			margin-left:10px;
		}
	}
	> .icon{
		height: 26px;		
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: relative;
		& svg{
			fill:#fff;
		}
		+ .text{
			margin-left:10px;
		}	
		&:empty {
			display: none;
		}
	}	
	&:focus{
		outline:none;
	}
	
`

const FormButton = (props) => {
	const { icon, text, id } = props;
	return(
		<FormBtn type="submit" className='btn' id={id}>
			<span className='text'>{text}</span><span className='icon'>{icon}</span>
		</FormBtn>
	)
}
export default FormButton