import React from "react"
import { Link } from "gatsby"
import { ViewLink } from "../../Link"
import {
  DropDown,
  DropItem,
  DropDownBody,
  DropDownFooter,
} from "./tags-component"

class GaragesDropdown extends React.Component {
  render() {
    return (
      <DropDown className="dropdown-menu">
        <DropDownBody className="dropdowm-body">
          {/* <DropItem>
            <Link to="/a-frame-horizontal-garages">
              A-Frame Horizontal Garages
            </Link>
          </DropItem>
          <DropItem>
            <Link to="/a-frame-vertical-garages">A-Frame Vertical Garages</Link>
          </DropItem>
          <DropItem>
            <Link to="/regular-style-garages">Regular Style Garages</Link>
          </DropItem> */}
          <DropItem>
            <Link to="/one-car-garages">One Car Garages</Link>
          </DropItem>
          <DropItem>
            <Link to="/two-car-garages">Two Car Garages</Link>
          </DropItem>
          <DropItem>
            <Link to="/three-car-garages">Three Car Garages</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-garage-prices">Metal Garage Prices</Link>
          </DropItem>
          <DropItem>
            <Link to="/rv-garages">RV Garages</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-garage-kits">Metal Garage Kits</Link>
          </DropItem>
        </DropDownBody>
        <DropDownFooter>
          <ViewLink to="/metal-garages">View All</ViewLink>
        </DropDownFooter>
      </DropDown>
    )
  }
}

export default GaragesDropdown
