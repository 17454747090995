import styled from "styled-components"
import BreakpointUp from "../../Media/BreakpointUp"

export const DropDown = styled.div`
	${BreakpointUp.xl`     
		position: absolute;
		background-color:#fff;
		box-shadow: 0px 2px 25px rgba(0,0,0,0.3);		
		max-width: 298px;
		min-width: 298px;
		width:100%;
		top:100%;
	`}		 
	&.dropdown-megamenu{
		${BreakpointUp.xl`     
			max-width: 894px;
			min-width: 894px;
			left: 50%;
			transform: translateX(-50%);
			display:flex;
			flex-wrap:wrap;
			.dropdowm-body{
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
				padding-left:15px;
				padding-right:15px;
			}
		`}
	}
	
`
export const DropDownBody = styled.div`
	${BreakpointUp.xl`	
		padding:10px 0;
	`}
	
`
export const DropDownHeader = styled.div`
	border-bottom: 1px solid rgb(227, 233, 242);
	font-size:18px;
	line-height: 24px;
	color: #000;
	font-weight:600;
	padding:10px 20px;
	cursor: default;
`
export const DropDownFooter = styled.div`
	border-bottom: 1px solid rgb(227, 233, 242);
	display: flex;
	${BreakpointUp.xl`	
		display:none;
	`}
	& a{
		display:block;
		
	}
`
export const DropItem = styled.div`
	padding:0 20px;
	border-bottom: 1px solid rgb(227, 233, 242);
	&:last-child{
		${BreakpointUp.xl`	
			border-bottom:none;
		`}
	}
	&.dropdown-submenu{
		> a{
			background-color: #dbdbdb;
			${BreakpointUp.xl`				
				background-color:transparent;
			`}
		}
		${BreakpointUp.xl`				
			background-color: #f7f7f7;
		`}
	}
	& a {
		display: flex;
		align-items: center;
		line-height: 20px;
		color: #444;
		font-weight:500;
		padding:10px;
		justify-content: space-between;
		${BreakpointUp.xl`
			padding:10px 0;			
			font-size:16px;
		`}
		& svg {
			fill:#444;
			transition: transform 0.3s ease-out;
			width:12px;
			display:none;
			${BreakpointUp.xl`
				display:block;
			`}
		}
		&:hover {			
			color: #000; 
			svg{				
				transform: translateX(4px);
				fill: #000; 				
			}  
		} 	
	}
`