import React from "react"
import styled from "styled-components"
import { BaseButton } from "../Section"

const PrimaryBtn = styled(BaseButton)`
  background-color: #b30000;
  border-color: #b30000;
  color: #fff;
  & .icon {
    > svg {
      fill: #fff;
    }
  }
  &:hover {
    background-color: transparent;
    border-color: #0b619b;
    color: #fff;
    &:before {
      background: #0b619b;
    }
  }
`
const PrimaryButton = props => {
  const { icon, text, textBefore } = props
  return (
    <PrimaryBtn className="btn">
      {textBefore ? (
        <>
          <span className="text">{textBefore}</span>
          <span className="icon">{icon}</span>
        </>
      ) : (
        <>
          <span className="icon">{icon}</span>
          <span className="text">{text}</span>
        </>
      )}
    </PrimaryBtn>
  )
}

export default PrimaryButton
