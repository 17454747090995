import React from 'react'
import { Link } from 'gatsby'
import { DropDown, DropItem, DropDownBody } from "./tags-component"

class FinancingRtoDropdown extends React.Component {
	render(){
		return(
			<DropDown className="dropdown-menu">	
				<DropDownBody className="dropdowm-body">										
					<DropItem><Link to='/rent-to-own-buildings'>Rent-To-Own</Link></DropItem>	
					<DropItem><Link to='/financing'>Financing</Link></DropItem>	
				</DropDownBody>
			</DropDown>
		)
	}
}
export default FinancingRtoDropdown