/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, schemaMarkup }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const commonSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Coast to Coast Carports",
    "image": "https://www.coast-to-coastcarports.com/static/logo-fb04b2560cb7268f2f063c6ab676d881.svg",
    "@id": "",
    "url": "https://www.coast-to-coastcarports.com",
    "telephone": "+18666817846",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "22525 I-40",
      "addressLocality": "Knoxville",
      "addressRegion": "AR",
      "postalCode": "72845",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 35.3859779,
      "longitude": -93.3597909
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "08:00",
      "closes": "17:00"
    },
    "sameAs": [
      "https://www.facebook.com/coast.to.coast.carports.inc",
      "https://twitter.com/C2CCarports",
      "https://www.pinterest.com/metalcarports"
    ]
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `46c8yd9c3jlxlwc5g5ryn0s01gymf9`,
        },
      ].concat(meta)}
    >
      {schemaMarkup ? (
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      ):(
        <script type="application/ld+json">{JSON.stringify(commonSchema)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
