import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  DropDown,
  DropItem,
  DropDownHeader,
  DropDownBody,
} from "./tags-component"

const PopularSizesDropdown = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulShopBySize {
        nodes {
          title
          type
        }
      }
    }
  `)

  const getShopBySizeUrl = (item) => {
    return item.title.split(" ").join("-").toLowerCase()
  }

  const items = data.allContentfulShopBySize.nodes
  const _20Items = items
    .filter(item => item.type === "20")
    .sort((a, b) => a.title.localeCompare(b.title))
  const _30Items = items
    .filter(item => item.type === "30")
    .sort((a, b) => a.title.localeCompare(b.title))
  const _40Items = items
    .filter(item => item.type === "40")
    .sort((a, b) => a.title.localeCompare(b.title))

  return (
    <>
      <DropDown className="dropdown-menu dropdown-megamenu">
        <DropDownBody className="dropdowm-body">
          <DropDownHeader>20' Wide</DropDownHeader>
          {_20Items.map(item => (
            <DropItem key={item.title}>
              <Link to={`/${getShopBySizeUrl(item)}`}>{item.title}</Link>
            </DropItem>
          ))}
        </DropDownBody>

        <DropDownBody className="dropdowm-body">
          <DropDownHeader>30' Wide</DropDownHeader>
          {_30Items.map(item => (
            <DropItem key={item.title}>
              <Link to={`/${getShopBySizeUrl(item)}`}>{item.title}</Link>
            </DropItem>
          ))}
        </DropDownBody>

        <DropDownBody className="dropdowm-body">
          <DropDownHeader>40' Wide</DropDownHeader>
          {_40Items.map(item => (
            <DropItem key={item.title}>
              <Link to={`/${getShopBySizeUrl(item)}`}>{item.title}</Link>
            </DropItem>
          ))}
        </DropDownBody>
      </DropDown>
    </>
  )
}

export default PopularSizesDropdown
