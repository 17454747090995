import React from "react"
import { Link } from "gatsby"
import { ViewLink } from "../../Link"
import {
  DropDown,
  DropItem,
  DropDownBody,
  DropDownFooter,
} from "./tags-component"

class CommercialDropdown extends React.Component {
  render() {
    return (
      <DropDown className="dropdown-menu">
        <DropDownBody className="dropdowm-body">
          <DropItem>
            <Link to="/metal-building-homes">Metal Building Homes</Link>
          </DropItem>
          <DropItem>
            <Link to="/clear-span-buildings">Clear-Span Buildings</Link>
          </DropItem>
          <DropItem>
            <Link to="/colonial-buildings">Colonial Buildings</Link>
          </DropItem>
          <DropItem>
            <Link to="/custom-buildings">Custom Buildings</Link>
          </DropItem>
          <DropItem>
            <Link to="/commercial-buildings">Commercial Buildings</Link>
          </DropItem>
          <DropItem>
            <Link to="/deluxe-buildings">Deluxe Buildings</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-sheds">Sheds</Link>
          </DropItem>
          <DropItem>
            <Link to="/storage-units">Storage Buildings</Link>
          </DropItem>
          <DropItem>
            <Link to="/warehouses">Warehouses</Link>
          </DropItem>
          <DropItem>
            <Link to="/workshop-buildings">Workshops</Link>
          </DropItem>
          <DropItem>
            <Link to="/barndominiums">Barndominiums</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-building-prices">Metal Building Prices</Link>
          </DropItem>
          <DropItem>
            <Link to="/metal-building-kits">Metal Building Kits</Link>
          </DropItem>
        </DropDownBody>
        <DropDownFooter>
          <ViewLink to="/metal-buildings">View All</ViewLink>
        </DropDownFooter>
      </DropDown>
    )
  }
}
export default CommercialDropdown
