import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { LeftArrowIcon, RightArrowIcon } from "../Icons"
import PrimaryButton from "../Button/PrimaryButton"

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: -220px;
  width: 220px;
  height: 220px;
  /* border-radius: 300px; */
  text-align: center;
  z-index: 100;
  transform: ${props => (props.show ? `translateX(220px)` : `translateX(0px)`)};
  transition: transform 0.3s linear;

  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      /* border-radius: 300px; */
      width: 100%;
      height: 100%;
    }
  }
  .text-wrap {
    position: relative;
    padding: 10px;
    margin-top: 30px;
    width: 100%;
    height: 100%;
    z-index: 102;
    span {
      color: white;
      font-size: 28px;
      line-height: 38px;
    }
    .btn {
      padding: 5px !important;
      text-align: center;
      font-size: 14px;
      border-radius: unset;

      .text {
        margin-left: 0;
        line-height: 18px;
      }
    }
  }
  .close-button {
    position: absolute;
    z-index: 110;
    width: 27px;
    height: 27px;
    /* border-radius: 30px; */
    top: 0;
    right: -27px;
    background: red;

    :hover {
      cursor: pointer;
    }
  }
`

const ADS = () => {
  const [showAds, setShow] = useState(true)

  const data = useStaticQuery(
    graphql`
      query adsQuery {
        allContentfulAds(
          filter: { active: { eq: true } }
          sort: { fields: createdAt, order: ASC }
        ) {
          edges {
            node {
              id
              adName
              ctaText
              ctaLink
              mainHeading
              backgroundImage {
                title
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      {data.allContentfulAds.edges[0] && (
        <Wrapper show={showAds}>
          <div className="close-button" onClick={() => setShow(!showAds)}>
            {showAds ? (
              <LeftArrowIcon fill="#ffffff" />
            ) : (
              <RightArrowIcon fill="#ffffff" />
            )}
          </div>
          <div className="background-img">
            <GatsbyImage
              image={
                data.allContentfulAds.edges[0].node.backgroundImage
                  .gatsbyImageData
              }
              alt={data.allContentfulAds.edges[0].node.backgroundImage.title}
            />
          </div>
          <div className="text-wrap">
            <span>{data.allContentfulAds.edges[0].node.mainHeading}</span>
            <Link to={data.allContentfulAds.edges[0].node.ctaLink}>
              <PrimaryButton
                text={data.allContentfulAds.edges[0].node.ctaText}
              />
            </Link>
          </div>
        </Wrapper>
      )}
    </>
  )
}

export default ADS
